
.melding {
  max-width: 500px;
}

.mat-stepper-horizontal .mat-horizontal-stepper-header {
  padding: 10px;
  height: auto;
}

.mat-stepper-horizontal .mat-stepper-horizontal-line {
  margin: 0 -4px;
}

.wrapper{
  display: block;
  position: relative;
}

.login-page {
  display: flex;
  padding: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  .content {
    z-index: 1;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: $white;
    max-width: 750px;
    box-shadow: 0 0 20px #ddd;


    input:-webkit-autofill {
      box-shadow: 0 0 0 30px $white inset;
      -webkit-box-shadow: 0 0 0 30px $white inset;
    }
  }

  &:after {
    content: '';
    background: $white;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 50%;
    right: 0;
  }

  &:before {
    content: '';
    background: $primary-color;
    position: absolute;
    top: 50%;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

