/* You can add global styles to this file, and also import other style files */

.alert {
  padding: 20px;
  border-radius: .25rem;
  margin: 10px 0 10px 0;
}

.alert-info {
  background-color: #cff4fc;
  border-color: #b6effb;
  color: #055160
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.mat-card-content {
  position: relative;
}
