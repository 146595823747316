/* You can add global styles to this file, and also import other style files */

@import './scss/material';
@import './scss/utils';
@import './scss/responsive';
@import './scss/variables';
@import './scss/theme';
@import "./scss/commons-alerts";
@import "./scss/common-login";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.ngb-dp-day {
  height: 100% !important;
  background-color: lightgrey;
  &:hover{
    cursor: pointer;
  }
  .normally-closed{
    background-color: #f8d7da;
  }
  .open{
    background-color: #d4edda;
  }
  .specially-closed{
    background-color: #fff3cd;
  }
  &.hidden{
    background-color: transparent;
  }
}

.pre-line {
  white-space: pre-line
}
.mat-success {
  color: #fff !important;
  background-color: #28a745 !important;
}

.mat-success[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.block-center {
  margin-top: 5px;
  text-align: center;
  display: block
}

.infoline{
  margin: 5px 0;
}
.label{
  float: left;
  padding-right: 20px;
  display: inline-block;
  font-weight: bold;
  min-width: 30%;
}
.value{
  display: inline-block;
  max-width: 50%;
}
mat-icon{
  vertical-align: bottom;
}

.custom-form {
  .mat-input-element {
    color: black!important;;
  }
  .mat-input-element {
    color: black!important;;
  }
  .mat-form-field-label {
    color: black!important;;
  }
}